.batteryLine {
  color: var(--Light-Surface-color-Grey-900, #272729);
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Roboto Flex", sans-serif;
  border-bottom: 2px solid #bbb;
  font-size: 16px;
  font-style: normal;
  font-weight: 125;
  line-height: 120%;
  text-align: left;
  margin-left: 8px;
}

.batteryStatusHeading {
  color: var(--Light-Surface-color-Grey-900, #272729);
  font-family: "Roboto Flex", sans-serif;
  align-self: flex-start;
  font-optical-sizing: auto;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  font-variation-settings: "slnt" 0, "wdth" 100, "GRAD" 0, "XTRA" 468, "YOPQ" 79,
    "YTAS" 750, "YTDE"-203, "YTFI" 738, "YTLC" 514, "YTUC" 712;
  text-align: justify;
  margin-left: 8px;
  letter-spacing: -0.1px;
  font-weight: 550;
}

.batteryView {
  display: flex;
}
.batteryValueIcon {
  width: 100%;
}

.batteryMobileValueIcon {
  width: 50%;
}
.batteryValueInPercentage {
  color: var(--Light-Surface-color-Grey-900, #272729);

  /* Desktop/Heading 1 */
  font-family: "Roboto Flex";
  font-size: 56px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%; /* 78.4px */
}

.batteryMobileValueInPercentage {
  color: var(--Light-Surface-color-Grey-900, #272729);

  /* Desktop/Heading 1 */
  font-family: "Roboto Flex";
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%; /* 78.4px */
}

.percentageName {
  margin-top: 25px;
  margin-left: 10px;
}

.battery-tr {
  text-align: center;
  display: flex;
  padding: 10px 0px;
  align-items: center;
}

.battery-row-tr {
  text-align: center;
  display: flex;
  padding: 10px 0px;
  align-items: center;
}
.battery-div {
  padding: 38px;
  margin: 0px 0px 0px 32px;
}
/* Responsive design for mobile view */
@media only screen and (max-width: 768px) {
  .equipmentTable,
  .battery-tr {
    padding: 0px;
    text-align: center;
    align-items: center;
  }

  .battery-tr {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 0px;
    text-align: center;
    align-items: center;
    margin-left: 13px;
    margin-top: 15px;
  }

  .battery-row-tr {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 0px;
    text-align: center;
    align-items: center;
    margin-top: 5px;
  }
  .battery-div {
    margin: 5px 5px 10px !important;
    height: 100% !important;
  }

  .battery-tr td {
    width: 100%;
    text-align: left;
    box-sizing: border-box;
  }

   .batteryValueIcon {
    width: 70px; 
    height: auto;
  } 
  .batteryView {
    display: block;
  }
  .batteryValueInPercentage,
  .percentageName {
    display: contents;
    font-size: 34px;
  }

  .batteryValueInPercentage {
    margin-right: 5px;
  }

  .equipmentTable thead th {
    position: sticky;
    top: 0;
    background-color: #fff; /* Optional: to keep the header background visible */
    z-index: 1; /* Ensures the header stays above the table rows */
  }
}
