.chartSet {
  display: flex;
}

.chartSetWidth {
  width: 50%;
}

.tooltip {
  width: 200px;
  position: fixed;
  bottom: 0px;
  right: 0px;
}

.box-shadow {
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.07);
}

.filter-drop-down {
  display: flex;
  width: 300px;
  height: 56px;
  padding: 0px 16px;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Light-Secondary-Blue-300, #9cc4f2);
  background: var(--Light-Surface-color-Grey-50, #fff);
  box-shadow: 0px 0px 4px 0px rgba(119, 177, 237, 0.81);
  margin-top: 5px;
}

.dropdownAlignmentView {
  margin-left: 16px;
}

.dropdownOptionStyle {
  width: 100%;
}

.dropdownStyle {
  display: flex;
  width: 200px;
  height: 56px;
  padding: 0px 16px;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--Light-Secondary-Blue-300, #9cc4f2);
  background: var(--Light-Surface-color-Grey-50, #fff);
  box-shadow: 0px 0px 4px 0px rgba(119, 177, 237, 0.81);
  margin-top: 8px;
  color: var(--Light-Surface-color-Grey-900, #272729);
  text-align: justify;
}

/* .dropdownExportStyle {
  display: flex;
  width: 200px;
  height: 56px;
  padding: 0px 16px;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--scroll-bar-color);
  background: var(--Light-Surface-color-Grey-50, #fff);
  box-shadow: 0px 0px 4px 0px var(--Light-Surface-color-Grey-50, #fff);
  margin-top: 8px;
  color: var(--Light-Surface-color-Grey-900, #272729);
  text-align: justify;
}

.dropdownmobileExportStyle {
  display: flex;
  width:100px;
  height: 56px;
  padding: 0px 16px;
  align-self: center;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--scroll-bar-color);
  background: var(--Light-Surface-color-Grey-50, #fff);
  box-shadow: 0px 0px 4px 0px var(--Light-Surface-color-Grey-50, #fff);
  margin-top: 8px;
  color: var(--Light-Surface-color-Grey-900, #272729);
  text-align: justify;
} */

.optionValue {
  padding: 0.65rem 1.25rem !important;
  height: 40px;
}

.datepicker-border {
  display: flex;
  max-width: 220px;
  height: 56px;
  gap: 20px;
  border-radius: 8px;
  background-color: white;
  justify-content: space-between;
}

.datepicker-div {
  display: inline-flex;
  align-items: center;
  gap: 15px;
}

.div-filter-box {
  display: inline-flex;
  align-items: flex-start;
  gap: 15px;
}

.container-top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.top-chart {
  /* width: 40%; */
  /* display: flex; */
  height: 334px;
  padding: var(--24, 24px);
  flex-direction: column;
  align-items: center;
  gap: var(--24, 24px);
  border-radius: 8px;
  background: var(--Light-Surface-color-Grey-50, #fff);
  margin: 0px 20px 20px 32px;
}

.location-view {
  color: var(--Light-Surface-color-Grey-900, #272729);
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 125;
  line-height: 120%;
  margin-left: 20px;
}

.weather-div {
  padding: 38px;
  margin: 0px 0px 0px 32px;
}

.weatherTemp {
  color: var(--Light-Surface-color-Grey-900, #272729);
  /* Body/Body 2 */
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 125;
  line-height: 120%;
}

.weatherTempDegree {
  color: var(--Light-Surface-color-Grey-900, #272729);
  leading-trim: both;
  text-edge: cap;
  /* Desktop/Heading 4 */
  font-family: "Roboto Flex";
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
}

.weatherLine {
  color: var(--Light-Surface-color-Grey-900, #272729);
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Roboto Flex", sans-serif;
  border-bottom: 2px solid #bbb;
  font-size: 16px;
  font-style: normal;
  font-weight: 125;
  line-height: 120%;
  text-align: left;
}

.weatherSolarLine {
  color: var(--Light-Surface-color-Grey-900, #272729);
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 125;
  line-height: 120%;
  text-align: left;
}

.chartSetWidth {
  width: 40%;
  display: flex;
  height: 334px;
  padding: var(--24, 24px);
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Light-Surface-color-Grey-50, #fff);
  margin: 40px;
}

.row .column {
  width: 44%;
}

.chartNameDiv {
  color: var(--Light-Surface-color-Grey-900, #272729);
  font-family: "Roboto Flex", sans-serif;
  font-size: 15px;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  line-height: 120%;
  font-variation-settings: "slnt"0, "wdth"100, "GRAD"0, "XTRA"468, "YOPQ"79,
    "YTAS"750, "YTDE"-203, "YTFI"738, "YTLC"514, "YTUC"712;
}

.equipmentStatusHeading {
  color: var(--Light-Surface-color-Grey-900, #272729);
  font-family: "Roboto Flex", sans-serif;
  align-self: flex-start;
  font-optical-sizing: auto;
  font-size: 18px;
  font-style: normal;
  margin-left: 20px;
  font-weight: 700;
  line-height: 120%;
  font-variation-settings: "slnt"0, "wdth"100, "GRAD"0, "XTRA"468, "YOPQ"79,
    "YTAS"750, "YTDE"-203, "YTFI"738, "YTLC"514, "YTUC"712;
}

.equipmentStatusTextRow {
  color: var(--Light-Surface-color-Grey-900, #272729);
  margin-left: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid #bbb;
  font-family: "Roboto Flex", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-align: left;
}

.headerTextRow {
  margin-bottom: 8px;
  font-size: 16px;
  font-family: "Roboto Flex", sans-serif;
}

.statusTextRow {
  margin-bottom: 8px;
}

.equipmentRow {
  color: var(--Light-Surface-color-Grey-900, #272729);
  margin-bottom: 15px;
  font-family: "Roboto Flex", sans-serif;
  border-bottom: 1px solid #bbb;
  font-size: 16px;
  font-style: normal;
  font-weight: 125;
  line-height: 120%;
  text-align: left;
}

.online {
  font-family: "Roboto Flex", sans-serif;
  height: 25px;
  width: 65px;
  background: #11cf19;
  border-radius: 5px;
  color: #272729;
  text-align: center;
  text-align: center;
}

.offline {
  font-family: "Roboto Flex", sans-serif;
  height: 25px;
  width: 65px;
  background: #de0000;
  border-radius: 5px;
  color: #272729;
  text-align: center;
  text-align: center;
}

.lastRow {
  border-bottom: none;
}

.statusRectangle {
  width: 80%;
  display: flex;
  height: 320px;
  padding: var(--24, 24px);
  flex-direction: column;
  align-items: center;
  gap: var(--24, 24px);
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Light-Surface-color-Grey-50, #fff);
  margin: 40px;
}

.custom-tooltip {
  background: black;
  color: white;
  padding: 8px;
  border-radius: 6px;
}

.alert-title {
  font-family: "'Roboto Flex', sans-serif";
  font-weight: 600;
  font-size: 16px;
  color: #272729;
  margin: auto;
}

.alert-des {
  font-family: "'Roboto Flex', sans-serif";
  font-weight: 100;
  font-size: 14px;
  line-height: normal;
  color: #272729;
}

.lastSeen {
  font-family: "Roboto Flex", sans-serif;
  background: #25dba7;
  width: 233px;
  height: 40px;
  border-radius: 8px;
  color: var(--Light-Surface-color-Grey-50, #fff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  float: right;
}

.lastSeen.spinning .svg-inline--fa {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.weatherReport {
  background: #f5f5f5;
}

.success-dialog {
  background-color: #84ce66;
  color: #272729;
  font-weight: 100;
}

.error-dialog {
  /* background-color: #ff5d5d; */
  color: #272729;
  font-weight: 100;
}

.linkIcon {
  float: right;
  color: #272729;
}

.arrow-icon {
  color: #515154;
  width: 20px;
  height: 20px;
}

.arrow-icon-disable {
  color: #90909a;
}

.filterArrow, .filterArrow-no-hover {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  align-self: center;
  height: 50px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  background-color: transparent;
  cursor: pointer;
}

.filterArrow:hover {
  background-color: #f0f0f0;
}

.filterArrow-no-hover:hover {
  background-color: transparent;
}

.notification-alert {
  top: 530px;
  left: 1177px;
  width: 2300px;
  height: 131px;
  font-family: "Roboto Flex", sans-serif;
  float: right;
  z-index: 100000;
}

.alertLastSeenDialog .MuiAlert-icon,
.alertLastSeenDialog .MuiAlert-action .MuiAlert-icon css-1ytlwq5-MuiAlert-icon button {
  color: #272729 !important;
}

.alertLastSeenDialog .MuiPaper-root-MuiAlert-root .MuiAlert-icon {
  color: #272729 !important;
}

@media only screen and (max-width: 1000px) {
  .container-top {
    flex-direction: column;
    width: 100%;
  }

  .top-chart {
    width: 100%;
  }
}

.alertLastSeenDialog {
  width: 350px;
  position: absolute;
  bottom: 5px;
  right: 0;
  /* text-align: justify; */
  z-index: 99999;
}

.memberHeadingAlign {
  margin-top: 10px;
  overflow-y: "auto";
  max-height: "240px";
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.2) !important;
}

.Mui-error {
  color: rgba(0, 0, 0, 0.6) !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  color: black !important;
}

.alert-last-seen-dialog {
  display: none;
}

.filter-option-space {
  margin-right: 0;
  margin-left: auto;
}

.weather-tr {
  text-align: center;
  display: flex;
  padding: 10px 0px;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .css-1qsyw7s-MuiPaper-root-MuiAlert-root {
    line-height: 0.43 !important;
  }

  .weather-tr {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0px;
    text-align: center;
    align-items: center;
  }

  .filter-option-space {
    margin-right: 0;
    margin-left: 0;
  }

  .top-chart {
    padding: 10px;
    margin: 0px 10px 10px 20px;
  }

  .show-mobile {
    display: block;
  }

  .equipmentStatusHeading {
    margin-left: 8px;
    letter-spacing: -0.1px;
    font-weight: 550;
  }

  .memberHeadingAlign {
    margin-top: 10px;
  }

  .headerTextRow {
    font-family: "Roboto Flex", sans-serif;
    letter-spacing: -0.1px;
    font-weight: 550;
  }

  .css-1q04gal-MuiDateCalendar-root {
    height: 270px !important;
  }

  .css-1eurbeq-MuiPickersToolbar-root-MuiDatePickerToolbar-root {
    display: none !important;
  }

  .lastseen-mob {
    background: #25dba7;
    border-radius: 5px;
    padding: 8px 18px;
    cursor: pointer;
  }

  .lastseen-mob.spinning .svg-inline--fa {
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .css-yslrua-MuiTypography-root-MuiAlertTitle-root {
    line-height: 1 !important;
  }

  .mobileAlert {
    position: fixed;
    bottom: 42vh;
    width: 330px;
    left: 17%;
    transform: translate(-50%, 50%);
    z-index: 1000;
  }

  .weather-div {
    margin: 5px 5px 10px !important;
    height: 100% !important;
  }

  .weatherReport {
    width: 100%;
  }

  .location-view {
    margin-left: 8px;
    margin-top: 5px;
  }

  .weatherLine {
    margin-left: 8px;
  }

  .alertLastSeenDialog {
    display: none;
  }

  .alert-last-seen-dialog {
    display: block;
    position: fixed;
    bottom: 45vh;
  }
}