.siteViewMember {
  margin-left: 0px;
}
.siteView {
  padding: 0px;
  margin-left: 40px;
  margin-top: 35px;
}
.rowViewDesign {
  margin-bottom: 35px;
}

.inverterInfo {
  width: 1088px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.105),
    0 6px 20px 0 rgba(0, 0, 0, 0.105);
  background-color: var(--Light-Surface-color-Grey-50, #fff);
  padding: 30px;
  border-radius: 4px;
  height: 556px;
  padding: 32px;
}

.siteInfoView {
  color: var(--Light-Surface-color-Grey-900, #272729);
  /* Desktop/Heading 5 */
  font-family: "Roboto Flex";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 21.6px */
  margin-bottom: 0px;
}
.inverterViewName {
  color: var(--Light-Surface-color-Grey-900, #272729);
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  line-height: 120%;
  margin-top: 8px;
}

.centered-dropdown-container {
  display: flex;
  margin-top: 10px;
  margin-left: 3%;
}

@media only screen and (max-width: 768px) {
  .dropdown-toggle .dropdownStyle {
    width: 90%;
  }
  .siteBattery {
    padding: 12px;
    margin-bottom: 12px;
    margin-top: 12px;
  }
}
