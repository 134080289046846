.KPIEVChargerWidgets {
  padding-top: 64px;
  padding-bottom: 60px;
  padding-left: 54px;
  padding-right: 40px;
}

.kpiEvChargerView {
  border-radius: 8px;
  background: var(--Light-Surface-color-Grey-50, #fff);
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: flex-end;
  padding: 20px;
}

.tablewidth {
  width: 442px;
  height: 423px;
  opacity: 0px;
  margin: 40px;
  background: var(--Light-Surface-color-Grey-50, #fff);
  margin: 40px;
}

.lastSeen {
  margin-right: 40px;
}

.scrollContentBattery {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin-left: 9px;
}

.headerNameText {
  color: var(--Light-Surface-color-Grey-900, #272729);
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.chartHeading {
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  letter-spacing: 0em;
  text-align: left;
  width: 186.9px;
  height: 13px;
  top: 39px;
  left: 30px;
  align-self: flex-start;
  margin-bottom: 20px;
  font-family: "Roboto Flex", sans-serif;
  margin-left: 10px;
  margin-top: 10px;
}

.tableHeading {
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  font-family: "Roboto Flex", sans-serif;
  border-bottom: 1px solid #ddd;
}

.tableDataText {
  font-size: 16px;
  font-weight: 125;
  width: 100%;
  text-align: left;
  font-family: "Roboto Flex", sans-serif;
}

.chartSetWidthGrid {
  width: 40%;
  display: flex;
  height: 334px;
  padding: var(--24, 24px);
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Light-Surface-color-Grey-50, #fff);
  margin-left: 40px;
}

.alertEVChargerWarning {
  width: 410px;
  position: absolute;
  bottom: 5px;
  right: 0;
  text-align: justify;
  z-index: 99999;
}

.alertEVChargerDialog {
  width: 310px;
  position: absolute;
  bottom: 5px;
  right: 0;
  text-align: justify;
  z-index: 99999;
}

.lastRecord {
  background: #f6f6f8;
  font-size: 24px;
  text-align: left;
  margin-bottom: 12px;
}
.lastData {
  margin: 10px;
}
/* .chartSetWidthParameter {
    display: flex;
    height: 334px;
    padding: var(--24, 24px);
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--Light-Surface-color-Grey-50, #fff);
    margin-left: 30px;
  } */

@media only screen and (max-width: 768px) {
  .KPIEVChargerWidgets {
    display: none;
  }
}
